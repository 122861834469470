/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";

/* NAVIBAR SETTINGS OVERWRITED
-------------------------------------------------- */

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap; // allow us to do the line break for collapsing content
	align-items: center;
	justify-content: space-between; // space out brand from logo
	// padding: $navbar-padding-y $navbar-padding-x;
	padding: 0 0;

	// Because flex properties aren't inherited, we need to redeclare these first
	// few properities so that content nested within behave properly.
	> .container,
	> .container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
&.bg-light {
	background-color: #fff !important;
}
}


// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
	display: inline-block;
	padding-top: 0; //$navbar-brand-padding-y;
	padding-bottom: 0; //$navbar-brand-padding-y;
	margin-right: 0; //$navbar-padding-x;
	font-size: $navbar-brand-font-size;
	line-height: inherit;
	white-space: nowrap;

	@include hover-focus {
		text-decoration: none;
	}
	height: 90px;
	min-width: 286px;
	text-indent: -99999px;
	background: url(../images/header_logo.png) 0 0 no-repeat; //original size
}


// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
	display: flex;
	flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;

	.nav-link {
		// padding-right: 0;
		// padding-left: 0;
		padding: 0 1.25rem;
		line-height: 90px;
	}

	.dropdown-menu {
		position: static;
		float: none;
	}
	.nav-item {
		font-size: .9375rem;
		text-align: center;
		// line-height: 90px;
	&.inverse-gray {
		.nav-link {
			color: #fff;
			background-color: #333;
		&:hover {
			background-color: #666;
		}
		}
	}
	}
}


// Navbar text
//
//

.navbar-text {
	display: inline-block;
	padding-top: $nav-link-padding-y;
	padding-bottom: $nav-link-padding-y;
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orienation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
	flex-basis: 100%;
	// For always expanded or extra full navbars, ensure content aligns itself
	// properly vertically. Can be easily overridden with flex utilities.
	justify-content: flex-end !important;
	align-items: center;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
	padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
	font-size: $navbar-toggler-font-size;
	line-height: 1;
	background: transparent; // remove default button style
	border: $border-width solid transparent; // remove default button style
	@include border-radius($navbar-toggler-border-radius);

	@include hover-focus {
		text-decoration: none;
	}
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
	@each $breakpoint in map-keys($grid-breakpoints) {
		$next: breakpoint-next($breakpoint, $grid-breakpoints);
		$infix: breakpoint-infix($next, $grid-breakpoints);

		&#{$infix} {
			@include media-breakpoint-down($breakpoint) {
				> .container,
				> .container-fluid {
					padding-right: 0;
					padding-left: 0;
				}
			}

			@include media-breakpoint-up($next) {
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;

				.navbar-nav {
					flex-direction: row;

					.dropdown-menu {
						position: absolute;
					}

					.dropdown-menu-right {
						right: 0;
						left: auto; // Reset the default from `.dropdown-menu`
					}

					.nav-link {
						padding-right: 1.5rem; //.5rem;
						padding-left: 1.5rem; //.5rem;
					}
					.inverse-gray {
						.nav-link {
							padding-right: 3rem;
							padding-left: 3rem;
						}
					}
				}

				// For nesting containers, have to redeclare for alignment purposes
				> .container,
				> .container-fluid {
					flex-wrap: nowrap;
				}

				// scss-lint:disable ImportantRule
				.navbar-collapse {
					display: flex !important;
				}
				// scss-lint:enable ImportantRule

				.navbar-toggler {
					display: none;
				}
			}
		}
	}
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	background: transparent no-repeat center center;
}
.carousel-control-prev-icon {
	opacity: 0.4;
	background-image: $carousel-control-prev-icon-bg;
}
.carousel-control-next-icon {
	opacity: 0.4;
	background-image: $carousel-control-next-icon-bg;
}


/* ORIGINAL COMMON SETTINGS
-------------------------------------------------- */
body {
	background-color: #fff;
	color: #000;
}

.body-wrapper {
	background-color: #fff;
}

.contents {
	min-height: 600px;
}

em,
strong {
	font-style: normal;
}

.wp_social_bookmarking_light {
	display: flex;
	justify-content: flex-end;
}

.btn-org1 {
	width: 320px;
	height: 80px;
	border-radius: 0;
	color: #fff;
	background-color: #c00;
	border-color: #fff;
	border-width: 3px;
&:hover {
	color: #c00;
	background-color: transparent;
	border-color: #c00;
}
}


.page-title,
.unit-title,
.subunit-title {
	background-color: #fff;
	margin-bottom: 0;
	padding-bottom: 2rem;
	text-align: center;
	h2 {
		display: inline-block;
		margin: 0 auto;
		margin-bottom: 2rem;
		img {

		}
	}
}

.class-title {
	background-color: #000;
	img {
		width: 100%;
	}
	h2 {
		margin-bottom: 0;
	}
}

/* BREADCRUMBS MODULE
------------------------------ */
.breadcrumbs {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	padding-bottom: 3rem;
	.breadcrumb {
		margin-bottom: 0;
		background-color: #fff;
	}
	.breadcrumb-item {
		font-size: .875rem;
		+ .breadcrumb-item::before {
			content: ">>";
			font-size: .7rem;
			vertical-align: top;
			color: #666;
		}
		a {
			text-decoration: underline;
			color: #666;
		}
	&.active {
		color: #000;
	}
	}
}

.banners1 {
	.container {
		max-width: 1380px;
		.banner {
			margin-bottom: 2.5rem;
			width: 100%;
			img {
				max-width: 100%;
			}
		}
	}
}

.banners2 {
	margin-bottom: 2rem;
	.container {
		max-width: 1400px;
		.banner {
			margin-bottom: 2.5rem;
			width: 100%;
			img {
				max-width: 100%;
			}
		}
	}
}

.bottomlinks {
	.container {
		max-width: 1380px;
		margin-bottom: 2.5rem;
		.bottomlink {
			width: 100%;
			padding-top: 2rem;
		&.bl2 {
			border-left: 1px solid #d7d6cf;
		}
			img {
				max-width: 100%;
			}
			h2 {
				margin-bottom: 2rem;
			}
			ul {
				list-style: none;
				padding-left: 0;
				li {
					margin-bottom: .35rem;
				&::before {
					content: ">>";
					font-size: .8rem;
					vertical-align: top;
				}
					a {
						color: #000;
					}
				}
			}
		}
	}
}


/* FOOTER MODULE SETTINGS
------------------------------ */
.footer {
	background-color: #000;
	padding-bottom: 3rem;
	.addresslead {
		h2 {
			img {
				display: block;
				margin: 0 auto;
				max-width: 100%;
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
		}
	}
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.addressitems {
		padding-top: 2rem;
		padding-bottom: 2rem;
		background-color: #fff;
	}
	.footer-information {
		.container {
			max-width: 1200px;
		}
		padding-top: 3.5rem;
		background-color: #000;
		color: #fff;
		.row.fi2 {
			padding-top: .5rem;
		}
		.footer-id {
			width: 100%;
			border-bottom: 1px solid #fff;
			padding-bottom: 2rem;
			a {
				margin-left: .5rem;
			}
		}

		.footer-info {
			padding-top: 2rem;
			.f-info-add {
				font-size: .875rem;
				margin-bottom: 3rem;
				.fwb {
					font-weight: bold;
				}
				.dbl {
					// margin-left: 2rem;
				}
			}
			.f-info-title {
				font-weight: bold;
				margin-bottom: .5rem;
			}
			.f-info-text {
				margin-bottom: 1rem;
				.fwb {
					font-weight: bold;
					margin-bottom: 1rem;
				}
			}
		}

		.footer-sitemap {
			padding-top: 2rem;
			border-left: 1px solid #fff;
			ul {
				margin-left: 1.5rem;
				padding-left: 0;
				list-style: none;
				li {
					font-weight: bold;
					margin-bottom: 1rem;
				&:before {
					content: ">>";
					font-size: .7rem;
					vertical-align: top;
				}
					a {
						color: #fff;
					}
					ul {
						margin-left: 1.2rem;
						margin-top: 1rem;
						li {
							font-weight: normal;
						&:before {
							content: none;
						}
						}
					}
				}
			}
		}

	}
	.copyright {
		font-size: .875rem;
		margin-top: 3rem;
		margin-bottom: 0;
	}
}

/* FADE IN SCROLL TOP
------------------------------ */
#scrolltop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	a {
		background: #666;
		text-decoration: none;
		color: #fff;
		width: 48px;
		height: 48px;
		padding: 18px 14px;
		text-align: center;
		display: block;
		border-radius: 50%;
	&:hover {
		text-decoration: none;
		background: #999;
	}
	}
	.arrow {
		width: 20px;
		height: 20px;
		border: 5px solid;
		border-color: #fff #fff transparent transparent;
		transform: rotate(-45deg);
	}
}


/* RESPONSIVE CSS
-------------------------------------------------- */
@media (min-width: 1200px) and (max-width: 1240px) {
	.navbar-expand-xl {
		.navbar-nav {
			.nav-link {
				padding: 0 1.25rem;
			}
			.inverse-gray {
				.nav-link {
					padding-right: 2rem;
					padding-left: 2rem;
				}
			}
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Medium devices (tablets, less than 1200px)
@media (max-width: 1199px) {
	nav {
		.navbar-brand {
			margin: 0 auto;
		}
	}
	.navbar-toggler {
			margin-right: 1rem;
	}
	.navbar-nav {
		.nav-link {
			line-height: 3.5rem;
		}
	}
	.footer {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.footer-information {
		.footer-info {
			.f-info-add,
			.f-info-title,
			.f-info-text,
			.f-info-lead {
				margin-left: 1rem;
				margin-right: 1rem;
			}
			.f-info-lead {
				img {
					max-width: 100%;
				}
			}
		}
	}

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	.case-related {
		padding-bottom: 1rem;
		.unit-sub {
			margin-bottom: 2rem;
			.case-content {
				padding: 1rem 1.5rem 0 1.5rem;;
				ul {
					list-style: none;
					padding-left: 0;
					margin-bottom: .5rem;
				}
			}
			.case-image {
				img {
					margin: 0 auto;
					display: block;
				}
			}
		}
	}
	.footer {
		.addressitems {
			padding-bottom: 1rem;
			.item {
				margin-bottom: 1rem;
				text-align: center;
			}
		}
		.footer-information {
			.footer-id {
				width: 100%;
				text-align: center;
				border-bottom: 1px solid #fff;
			}
		}
		.footer-info {
			border-bottom: 1px solid #fff;
			padding-bottom: 3rem;
			text-align: center;
			.f-info-add {
				.dbl {
					margin-left: 0;
					display: block;
				}
			}
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) {

}

@media (min-width: 576px) and (max-width: 767px) {

}

// Extra small devices (portrait phones, less than 767px)
@media (max-width: 767px) {
	.banners1 {
		.container {
			max-width: auto;
			.banner {
				margin-bottom: 1.5rem;
				img {
					width: 100%;
				}
			}
		}
	}
	.banners2 {
		margin-bottom: 1rem;
		.container {
			max-width: auto;
			.banner {
				margin-bottom: 1.5rem;
				img {
					width: 100%;
				}
			}
		}
	}
	.bottomlinks {
		.container {
			max-width: auto;
			.bottomlink {
				padding-top: 1rem;
				margin-bottom: 1rem;
				border-top: 1px dashed #d7d6cf;
			&.bl2 {
				border-left: none;
			}
				img {
					width: 100%;
				}
				h2 {
					margin-bottom: 1.5rem;
				}
				ul {
					li {
						font-size: .875rem;
						line-height: 1.9rem;
					}
				}
			}
		}
	}
	.footer {
		.addresslead {
			margin-bottom: 1rem;
			h2 {
				img {
					max-width: 96%;
				}
			}
		}
		.addressitems {
			.item {
				margin-bottom: 1rem;
				text-align: center;
			}
		}
		.copyright {
			.arr {
				display: block;
			}
		}
	}

	.case-related {
		padding-bottom: 1rem;
		.unit-sub {
			margin-bottom: 2rem;
			.case-title {
				h5 {
					line-height: 1.3;
				}
			}
			.case-content {
				padding: 2rem 2rem 1.5rem 2rem;;
				ul {
					list-style: none;
					padding-left: 0;
					margin-bottom: .5rem;
				}
			}
		}
	}
}